import React, { FC } from 'react';
import styled from 'styled-components';
import { tabletBreakPoint, Text } from '../components';
import { LayoutPage } from '../layouts/LayoutPage';


const AboutWrapper = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    // padding: var(--space-l);

    @media ${tabletBreakPoint} {
        justify-content: center;
        align-items: center;
        align-content: center;
        // padding: var(--space-s);
    }
`;

const AboutPictureWrapper = styled.div`
    width: 150px;
    height: 150px;
    position: relative;
`;

const AboutPictureBG = styled.img`
    width: 150px;
    height: 150px;
    position:  absolute;
`;

const AboutPictureContent = styled.img`
    width: 150px;
    height: 150px;
    position:  absolute;
`;

const TextWrapper = styled.div`
    position: relative;
    max-width: 100%;

    ul {
        list-style: disc;
    }

    @media ${tabletBreakPoint} {
        padding: var(--space-s);

        .heading {
            text-align: center;
        }
    }
`;

const About: FC = () => (
    <LayoutPage>
        <AboutWrapper>
            <AboutPictureWrapper>
                <AboutPictureBG src="/about/psyche.gif" alt="" />
                <AboutPictureContent src="/about/tete.gif" alt="" />
            </AboutPictureWrapper>

            <TextWrapper>
                <br />
                <Text className="heading" whiteSpace="normal">Hello, I live, eat, sleep and draw in Indre-et-Loire (37, France)</Text>
                <br />
            </TextWrapper>

        </AboutWrapper>
    </LayoutPage>
);

export default About;
